import './TermsAndConditions.css';

function TermsAndConditions() {
  return (
    <div className="TermsAndConditions">
        <div className="TermsAndConditionsInner">
              <h1>Terms and Conditions for Rage of Mechs (RoM)</h1>
              <h4>Last Updated: 20 Dec 2023</h4>

              <h3>1. Introduction</h3>
              <p>Welcome to Rage of Mechs, a multiplayer turn-based strategy game developed and published by Custom Software Development Limited ("we," "us," or "our"). These Terms and Conditions ("Terms") govern your access to and use of Rage of Mechs and its related services. By accessing or using our game, you agree to be bound by these Terms and all terms incorporated by reference. If you do not agree to all of these Terms, do not use our game.</p>

              <h3>2. Acceptance of Terms</h3>
              <p>By accessing and playing Rage of Mechs, you signify your agreement to these Terms. If you do not agree to these Terms, please do not use our game.</p>

              <h3>3. User Account</h3>
              <ul>
                  <li>User accounts are managed through Steam. You must have a valid Steam account to play Rage of Mechs.</li>
                  <li>You are responsible for all activities that occur under your Steam account used to play Rage of Mechs.</li>
                  <li>You agree to comply with Steam’s terms and conditions in addition to these Terms.</li>
                  <li>You must ensure that your account information remains up to date and accurate.</li>
              </ul>

              <h3>4. Game Services</h3>
              <ul>
                    <li>Rage of Mechs is a multiplayer, turn-based strategy game</li>
                    <li>You agree to engage in fair gameplay and uphold the spirit of competition.</li>
                    <li>Use of cheats, exploits, or any unauthorized third-party software to modify the game is strictly prohibited.</li>
              </ul>


              <h3>5. User Content</h3>
              <p>You may generate content, such as text or images, while using our game.</p>
              <p>You grant Custom Software Development Limited a non-exclusive, transferable, sub-licensable, royalty-free, worldwide license to use any content that you post in connection with the game.</p>

              <h3>6. Prohibited Conduct</h3>
              <ul>
                    <li>Engaging in any form of harassment, abuse, or hate speech is strictly prohibited.</li>
                    <li>You may not use our services to post or transmit any illegal material, including material that is defamatory, obscene, or otherwise objectionable.</li>
              </ul>

              <h3>7. Purchases and Subscriptions</h3>
              <p>Any in-game purchases or subscriptions will be processed through Steam.</p>
              <p>Refund and cancellation policies are subject to the terms provided by Steam.</p>

              <h3>8. Intellectual Property Rights</h3>
              <p>The game and its contents are owned by Custom Software Development Limited, its licensors, or other providers of such material.</p>

              <h3>9. Disclaimers and Limitation of Liability</h3>
              <p>The game is provided on an "as is" and "as available" basis without any warranties.</p>
              <p>Custom Software Development Limited will not be liable for any damages arising from the use of the game.</p>

              <h3>10. Termination and Suspension</h3>
              <p>We reserve the right to suspend or terminate your access to the game if you violate these Terms or Steam’s terms and conditions.</p>

              <h3>11. Dispute Resolution</h3>
              <p>These Terms are governed by and construed in accordance with the laws of Hong Kong, without regard to its conflict of law principles.</p>
              <p>Any dispute arising from these Terms will be resolved in the courts located in Hong Kong.</p>

              <h3>12. Changes to Terms and Conditions</h3>
              <p>We reserve the right to modify these Terms at any time and will notify users of any changes by updating the date at the top of these Terms.</p>

              <h3>13. Contact Information</h3>
              <p>For support or questions about these Terms, please contact us at <a href="mailto:rageofmechs@gmail.com">rageofmechs@gmail.com</a>.</p>
        </div>
    </div>
  );
}

export default TermsAndConditions;
