import SubscribeForm from './components/SubscribeForm/SubscribeForm';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="content">
        <div className="top">
          <div className="logo"/>
          <div className="subtitle" children="Post-apocalyptic tactical game"/>
          <div className="social_wrp">
            <div className="social">
              <a href="https://discord.com/invite/z3EYw6MDzk" className="discord" target="_blank">Discord</a>
              <a href="https://www.youtube.com/channel/UCYjO736bU8MHpeRw-MOaD9Q" className="youtube" target="_blank">Youtube</a>
              <a href="https://store.steampowered.com/app/2518000/Rage_of_Mechs" className="steam" target="_blank">Steam</a>
              <a href="https://www.instagram.com/rageofmechs/" className="instagram" target="_blank">Instagram</a>
            </div>
          </div>
        </div>
        <div className="section2">
          <h3>Welcome to the world of Rage of Mechs!</h3>

          <p>World where teams of mechs fight each other in the post-apocalyptic wasteland full of dangerous anomalies and mutants.</p>
          <p>As a skilled commander, you must guide your team of mechs through treacherous terrain and defeat other players teams of mechs in a multi-player turn-based sessions to emerge victorious.</p>

          <p>Use your in-game laboratory to research new weapons and technologies to upgrade your mechs and create your own unique build of mech team.</p>
          <p>You decide what team you build: strong mech with heavy machineguns and lots of armory plates or light scout with no guns, but full of radar modules and camouflage systems. Your team quantity only depends on weight quota.</p>
          <p>Train pilots to learn new skills and test them on battlefield using strategic tactics and tactical equipment. But beware, the other players are just as ruthless and will stop at nothing to destroy you.</p>
          <p>As the player progresses, matchmaking system will make player to encounter stronger and more formidable opponents, requiring strategic thinking and unique team builds.</p>
          <p>
            Stay sharp and continue to hone your skills, for you never know what new challenges and enemies await you in the wasteland.
            Until then, may you rage fuel your victory in every battle.
          </p>

          {/*<h3>Distant future...</h3>*/}
          {/*<p>After the Great War Earth is ruined by man-made catastrophe and keep slowly dying. Apart from radiation, anomalies and mutants new masters of the modern Earth.</p>*/}
          {/*<p>Elites moved to Mars leaving their home to be torn apart by the worst of the worst. Secret societies residing on Mars engage in clandestine battles on Earth, investing resources in Mechs’ gear and weaponry. The inhabitants of the Earth act as the pilots of the Mechs.</p>*/}
          {/*<p>The winner takes it all — a ticket to Mars, a ticket to a new life...</p>*/}
        </div>
        <div className="section3">
          <div className="intouch">Stay in touch</div>
          <SubscribeForm />
        </div>
      </div>
      <footer>
        <div className="copy">2024 © CPCS. Rage of Mechs</div>
        <div className="footer_social">
          <div className="social">
            <a href="https://discord.gg/5dXzAHgxMX" className="discord" target="_blank">Discord</a>
            <a href="https://www.youtube.com/channel/UCYjO736bU8MHpeRw-MOaD9Q" className="youtube" target="_blank">Youtube</a>
            <a href="https://store.steampowered.com/app/2518000/Rage_of_Mechs" className="steam" target="_blank">Steam</a>
            <a href="https://www.instagram.com/rageofmechs/" className="instagram" target="_blank">Instagram</a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
