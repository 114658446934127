import React, { useState, useCallback } from 'react';
import axios from 'axios';
import cx from 'classnames';
import './SubscribeForm.css';

// const BASE_API_URL = process.env.NODE_ENV === 'development' ? '/api' : 'https://stage.rageofmechs.of-it.org/api';
const BASE_API_URL = 'https://stage.rageofmechs.of-it.org/api';

const validateEmail = (v) => {
  if (!v) return 'Email is required';
  // const reg = /^[^@]+@[^@.]+.[^@.][^@]*$/;
  const valid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(v);
  return valid ? null : 'Invalid Email';
};

const SUBMIT_RESULT = {
  types: {
    SUCCESS: 'SUCCESS',
    WARNING: 'WARNING',
    ERROR: 'ERROR',
    EMPTY: 'EMPTY',
  },
  type: 'EMPTY',
  message: '',
};

const parseError = err => {
  if (err?.response?.data?.message) {
    return err.response.data.message;
  }
  if (err?.response?.statusText) {
    return [err.response.status, err.response.statusText].join(' ');
  }
  return 'Something went wrong';
}

export default function SubscribeForm() {
  const [email, setEmail] = useState('');
  const [emailLostFocus, setEmailLostFocus] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [submitResult, setSubmitResult] = useState(SUBMIT_RESULT);
  const emailRef = useCallback((node) => {
    // make emailRef usable as described in doc
    emailRef.current = node;
    if (!node) {
      return;
    }
    // validate empty email field when component mounts
    setEmailError(validateEmail(node.value));
    // attach event handler
    node.onblur = () => {
      setEmailLostFocus(true);
    }
  }, []);
  emailRef.current = emailRef.current || null;
  const onEmailChange = useCallback((event) => {
    const { value } = event.target;
    const err = validateEmail(value);
    setEmailError(err);
    setEmail(value);
  }, []);
  const onSubmitFails = useCallback((err) => {
    console.error('Form submit fails with error:', { err });
    setEmailError(parseError(err));
    setSubmitResult({
      type: SUBMIT_RESULT.types.ERROR,
      message: 'something went wrong, see console for details',
    });
  }, []);
  const onSubmit = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      // event.persist();
      setEmailLostFocus(true);
      const errorMessage = validateEmail(email);
      setEmailError(errorMessage);
      if (errorMessage) {
        return;
      }
      // const formData = new FormData(event.target);
      // formData.append("username", "Groucho");
      // formData.append("nested", { foo: 'bar' });
      // const data = Object.fromEntries(formData);
      // formData.forEach(...)
      axios.post(
        `${BASE_API_URL}/mailings/subscribe`,
        { email, username: 'undefined' },
      ).then((resp) => {
        setSubmitResult({
          type: SUBMIT_RESULT.types.SUCCESS,
          message: 'Thank you for subscription!',
        });
      }).catch(onSubmitFails);
    } catch (err) {
      onSubmitFails(err);
    }
  }, [email, onSubmitFails]);
  return (
    <form className="subscribe_form" onSubmit={onSubmit}>
      {
        submitResult.type !== SUBMIT_RESULT.types.SUCCESS &&
          <React.Fragment>
            <div className={cx('form-row', { 'lost-focus': emailLostFocus, invalid: emailError })}>
              <input
                type="email"
                name="email"
                value={email}
                ref={emailRef}
                onBlur={console.warn}
                onChange={onEmailChange}
                placeholder="Your Email"
                className="form-field email"
              />
              <div className="validation-overlay">
                {emailError}
              </div>
            </div>
            <button
              type="submit"
              disabled={submitResult.type === SUBMIT_RESULT.types.SUCCESS}
            >Subscribe</button>
          </React.Fragment>
      }
      {
        submitResult.type === SUBMIT_RESULT.types.SUCCESS &&
          <div className="success-submit-message">
            Thank you for your subscription!
          </div>
      }
    </form>
  );
}
